<template>
  <div class="frenzy-fund">
    <div class="loginView">
      <h1 class="frenzy-fund-title">FrenzyFund</h1>
      <div>
        <!-- Display an error message if there is an error signing in -->
        <div v-if="error" class="error">{{ error }}</div>

        <!-- Display the login button -->
        <button @click="loginWithTwitch">Login with Twitch</button>
      </div>
    </div>
  </div>
</template>

<script>
import { createClient } from "@supabase/supabase-js"; // Import the Supabase client

export default {
  data() {
    return {
      error: null, // Initialize the error to null
    };
  },
  created() {
    // Create a new Supabase client
    this.supabase = createClient(
      "https://nankyhhebwlbyytlkozy.supabase.co",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5hbmt5aGhlYndsYnl5dGxrb3p5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njc1MDQ0ODQsImV4cCI6MTk4MzA4MDQ4NH0.oVi7tGrlG5rLPe-xfkwdEzpszFFx5IZ-Wd-fPYoKZIY"
    );
  },
  methods: {
    async loginWithTwitch() {
      try {
        // Attempt to sign in with Twitch OAuth
        let { data, error } = await this.supabase.auth.signInWithOAuth({
          provider: "twitch",
          options: {
            redirectTo: "https://frenzyfund.com/auth",
          },
        });

        // If there is an error, display it
        if (error) {
          this.error = error;
        } else {
          // Otherwise, handle the successful login
          console.log(data);
        }
      } catch (error) {
        // Display any errors that occur
        this.error = error;
      }
    },
  },
};
</script>

<style>
.error {
  color: red;
}
</style>
