import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";

try {
  const app = createApp(App).use(createPinia()).use(router);
  app.mount("#app");
} catch (error) {
  console.error(error);
}
