import { createRouter, createWebHistory } from "vue-router";
import dashboardView from "@/views/DashboardView.vue";
import streamView from "@/views/OverlayView.vue";
import loginView from "@/views/LoginView.vue";
import authCheck from "@/views/authCheck.vue";
import goalView from "@/views/GoalEditor.vue";

const routes = [
  {
    path: "/",
    name: "dashboardView",
    component: dashboardView,
  },
  {
    path: "/overlay",
    name: "streamView",
    component: streamView,
  },
  {
    path: "/login",
    name: "loginView",
    component: loginView,
  },
  {
    path: "/goal",
    name: "goalView",
    component: goalView,
  },
  {
    path: "/auth",
    name: "authCheck",
    component: authCheck,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
