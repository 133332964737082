<template>
  <div id="overlayView">
    <ul class="frenzy-fund-goals">
      <li
        v-for="goal in goals"
        :key="goal.id"
        :class="'frenzy-fund-goal frenzy-fund-goal-' + goal.id"
      >
        #{{ goal.tag }} - {{ goal.goal_description }}
        <div class="goal-progress">
          <div
            class="goal-progress-bar"
            :style="{
              width:
                goal.goal_tally >= goal.goal_target
                  ? '100%'
                  : (goal.goal_tally / goal.goal_target) * 100 + '%',
            }"
          ></div>
          <div class="goal-progress-text">
            {{
              goal.goal_tally >= goal.goal_target
                ? "We have reached our goal of $" + goal.goal_target + "!"
                : "Currently $" +
                  (goal.goal_tally || 0) +
                  " of $" +
                  goal.goal_target +
                  " collected."
            }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style>
.frenzy-fund {
  text-align: center;
  padding: 20px;
}

.frenzy-fund-title {
  color: #1313e7;
  font-size: 2em;
  margin: 20px;
  margin-bottom: 20px;
  /* display: inline-block; */
  padding: 5px;
  background-color: #0400f550;
  border-radius: 10px;
}

.frenzy-fund-goals {
  color: #ffffff;
  list-style: none;
  margin: 0;
  padding: 0;
}

.frenzy-fund-goal {
  /* display: inline-block; */
  margin: 10px;
  padding: 20px;
  background-color: #000099;
  border-radius: 10px;
}

.goal-progress {
  height: 20px;
  background-color: #8693ab;
  border-radius: 5px;
  position: relative;
}

.goal-progress-bar {
  height: 100%;
  background-color: #2196f3;
  border-radius: 5px;
}

.goal-progress-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.8em;
}
</style>

<script>
import { createClient } from "@supabase/supabase-js";

export default {
  name: "overlayView",
  computed: {
    progressStyles() {
      return this.goals.map((goal) => ({
        width: `${(goal.goal_tally / goal.goal_target) * 100}%`,
      }));
    },
  },
  data() {
    return {
      supabase: null,
      goals: [],
    };
  },
  created() {
    this.supabase = createClient(
      "https://nankyhhebwlbyytlkozy.supabase.co",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5hbmt5aGhlYndsYnl5dGxrb3p5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njc1MDQ0ODQsImV4cCI6MTk4MzA4MDQ4NH0.oVi7tGrlG5rLPe-xfkwdEzpszFFx5IZ-Wd-fPYoKZIY"
    );
    this.fetchGoals();

    // Refresh goals every 5 seconds
    setInterval(() => {
      this.fetchGoals();
    }, 5000);
  },
  methods: {
    async fetchGoals() {
      let { data: goals, error } = await this.supabase
        .from("goals")
        .select("tag,goal_description,goal_target,goal_tally")
        .is("active", true);
      if (error) {
        // Handle the error
        return;
      }
      this.goals = goals;
    },
  },
  // ...
};
</script>
