<template>
  <div id="dashboardView">
    <div class="frenzy-fund">
      <h1 class="frenzy-fund-title">Logging into FrenzyFund...</h1>
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { createClient } from "@supabase/supabase-js"; // Import the Supabase client

export default {
  name: "authCheck",
  data() {
    return {
      authenticated: false,
      avatarUrl: null,
      nickname: null,
    };
  },
  beforeCreate() {
    // Create a new Supabase client
    this.supabase = createClient(
      "https://nankyhhebwlbyytlkozy.supabase.co",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5hbmt5aGhlYndsYnl5dGxrb3p5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njc1MDQ0ODQsImV4cCI6MTk4MzA4MDQ4NH0.oVi7tGrlG5rLPe-xfkwdEzpszFFx5IZ-Wd-fPYoKZIY"
    );
    try {
      // Check if the user is authenticated
      let authToken = localStorage.getItem(
        "sb-nankyhhebwlbyytlkozy-auth-token"
      );
      if (authToken) {
        // Redirect the user to the dashboard
        this.$router.push("/");
      } else {
        // Delay the page reload by 1 second using setTimeout
        setTimeout(() => {
          location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style>
#dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
}

.spinner > div {
  width: 60px;
  height: 60px;
  background-color: #000099;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
