<template>
  <div id="adminView">
    <h1>Goal Editor</h1>
    <table style="border-collapse: collapse; width: 80%">
      <thead>
        <tr style="border: 1px solid #ddd">
          <th
            style="
              padding: 8px;
              text-align: left;
              border: 1px solid #ddd;
              width: 3%;
            "
          >
            ID
          </th>
          <th
            style="
              padding: 8px;
              text-align: left;
              border: 1px solid #ddd;
              width: 5%;
            "
          >
            Tag
          </th>
          <th
            style="
              padding: 8px;
              text-align: left;
              border: 1px solid #ddd;
              width: 35%;
            "
          >
            Description
          </th>
          <th
            style="
              padding: 8px;
              text-align: left;
              border: 1px solid #ddd;
              width: 10%;
            "
          >
            Target Amount
          </th>
          <th
            style="
              padding: 8px;
              text-align: left;
              border: 1px solid #ddd;
              width: 10%;
            "
          >
            Current Donations
          </th>
          <th
            style="
              padding: 8px;
              text-align: left;
              border: 1px solid #ddd;
              width: 4%;
            "
          >
            Active
          </th>
          <th
            style="
              padding: 8px;
              text-align: left;
              border: 1px solid #ddd;
              width: 9%;
            "
          ></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(goal, index) in goals"
          :key="goal.goal_id"
          :style="[index % 2 == 0 ? 'background-color: #f2f2f2' : '']"
        >
          <td style="padding: 8px; text-align: left; border: 1px solid #ddd">
            {{ goal.goal_id }}
          </td>
          <td style="padding: 8px; text-align: left; border: 1px solid #ddd">
            <input
              v-model="goal.tag"
              v-if="goal.editing"
              type="text"
              style="border: none; outline: none; width: 100%"
            />
            <span v-else>#{{ goal.tag }}</span>
          </td>
          <td style="padding: 8px; text-align: left; border: 1px solid #ddd">
            <input
              v-model="goal.goal_description"
              v-if="goal.editing"
              type="text"
              style="border: none; outline: none; width: 100%"
            />
            <span v-else>{{ goal.goal_description }}</span>
          </td>
          <td style="padding: 8px; text-align: left; border: 1px solid #ddd">
            <input
              v-model="goal.goal_target"
              v-if="goal.editing"
              type="number"
              style="border: none; outline: none; width: 100%"
            />
            <span v-else>${{ goal.goal_target }}</span>
          </td>
          <td style="padding: 8px; text-align: left; border: 1px solid #ddd">
            <span>${{ goal.goal_tally }}</span>
          </td>
          <td style="padding: 8px; text-align: left; border: 1px solid #ddd">
            <input
              v-model="goal.active"
              v-if="goal.editing"
              type="checkbox"
              style="outline: none"
            />
            <span v-else>{{ goal.active ? "Yes" : "No" }}</span>
          </td>
          <td style="padding: 8px; text-align: center; border: 1px solid #ddd">
            <button
              v-if="goal.editing"
              @click="saveGoal(goal)"
              style="
                outline: none;
                border: none;
                background-color: #4caf50;
                color: white;
                padding: 8px 16px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
                cursor: pointer;
              "
            >
              Save
            </button>
            <button
              v-if="goal.editing"
              @click="deleteGoal(goal)"
              style="
                outline: none;
                border: none;
                background-color: #c40808;
                color: white;
                padding: 8px 16px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
                cursor: pointer;
              "
            >
              Delete
            </button>
            <button
              v-else
              @click="editGoal(goal)"
              style="
                outline: none;
                border: none;
                background-color: #f2f2f2;
                color: black;
                padding: 8px 16px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
                cursor: pointer;
              "
            >
              Edit
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <p></p>
    <button
      @click="newGoal"
      style="
        outline: none;
        border: none;
        background-color: #f2f2f2;
        color: black;
        padding: 8px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        cursor: pointer;
      "
    >
      New Goal
    </button>
  </div>
</template>

<script>
import { createClient } from "@supabase/supabase-js"; // Import the Supabase client

export default {
  name: "adminView",
  data() {
    return {
      supabase: null,
      session: null,
      error: null,
      goals: [],
    };
  },
  async created() {
    // Create the Supabase client object
    this.supabase = createClient(
      "https://nankyhhebwlbyytlkozy.supabase.co",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5hbmt5aGhlYndsYnl5dGxrb3p5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njc1MDQ0ODQsImV4cCI6MTk4MzA4MDQ4NH0.oVi7tGrlG5rLPe-xfkwdEzpszFFx5IZ-Wd-fPYoKZIY"
    );

    // Fetch the current user's session
    const { data, error } = await this.supabase.auth.getSession();
    this.session = data;
    this.error = error;

    // Fetch the goals from the database
    await this.fetchGoals();
  },
  methods: {
    async fetchGoals() {
      console.log("Fetching goals...");
      const { data, error } = await this.supabase.from("goals").select("*");
      console.log(data, error);
      this.goals = data;
      console.log("Goals fetched!");
      console.log(this.goals);
    },
    editGoal(goal) {
      goal.editing = true;
    },
    async saveGoal(goal) {
      delete goal.editing;
      console.log(goal);
      await this.supabase
        .from("goals")
        .update(goal)
        .eq("goal_id", goal.goal_id);
      goal.editing = false;
    },
    async newGoal() {
      const { goal, error } = await this.supabase
        .from("goals")
        .insert({ goal_description: "New Goal" });
      console.log(goal, error);
      await this.fetchGoals();
    },
    async deleteGoal(goal) {
      // Confirm with the user if they want to delete the goal
      if (confirm("Are you sure you want to delete this goal?")) {
        goal.editing = false;
        try {
          await this.supabase
            .from("goals")
            .delete()
            .eq("goal_id", goal.goal_id);
          // Remove the goal from the goals array in the component's data
          this.goals = this.goals.filter((g) => g.goal_id !== goal.goal_id);
        } catch (error) {
          // Handle the error here, for example by displaying an error message to the user
          console.error(error);
        }
      }
    },
  },
};
</script>

<style>
#adminView {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
