<template>
  <div id="overlayView">
    <div class="frenzy-fund">
      <h1 class="frenzy-fund-title">FrenzyFund</h1>
      <adminView />
      <nav>
        | <router-link to="/">Dashboard</router-link> |
        <a @click="signOut" style="text-decoration: underline; cursor: pointer"
          >Logout</a
        >
        |
      </nav>
    </div>
  </div>
</template>

<script>
import adminView from "@/components/AdminView.vue";
import { createClient } from "@supabase/supabase-js"; // Import the Supabase client

export default {
  name: "goalView",
  components: {
    adminView,
  },
  created() {
    // Create the Supabase client object
    this.supabase = createClient(
      "https://nankyhhebwlbyytlkozy.supabase.co",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5hbmt5aGhlYndsYnl5dGxrb3p5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njc1MDQ0ODQsImV4cCI6MTk4MzA4MDQ4NH0.oVi7tGrlG5rLPe-xfkwdEzpszFFx5IZ-Wd-fPYoKZIY"
    );
    setInterval(() => {
      this.ff_pulse();
    }, 30000);
  },
  methods: {
    async ff_pulse() {
      let { data, error } = await this.supabase.rpc("ff_pulse");

      if (error) console.error(error);
      else console.log(data);
    },
    async signOut() {
      let { error } = await this.supabase.auth.signOut();
      if (error) {
        console.error(error);
      } else {
        this.authenticated = false;
      }
    },
  },
};
</script>

<style>
#dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
