<template>
  <div id="dashboardView">
    <div class="frenzy-fund">
      <h1 class="frenzy-fund-title">FrenzyFund</h1>
      <overlayView />
      <nav v-if="!authenticated">
        | <router-link to="/login">Streamer Login</router-link> |
      </nav>
      <div v-else>
        <p>
          You are currently logged in as
          <img
            v-bind:src="avatarUrl"
            alt="User avatar"
            style="transform: translateY(25%)"
          />
          {{ nickname }}.
        </p>
        <nav>
          | <router-link to="/goal">Goal Editor</router-link> |
          <a
            @click="signOut"
            style="text-decoration: underline; cursor: pointer"
            >Logout</a
          >
          |
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import overlayView from "@/components/OverlayView.vue";
import { createClient } from "@supabase/supabase-js"; // Import the Supabase client
import { ref } from "vue";

export default {
  name: "dashboardView",
  components: {
    overlayView,
  },
  data() {
    return {
      authenticated: ref(false),
      accessToken: ref(null),
      username: ref(null),
      nickname: ref(null),
      avatarUrl: ref(null),
      authenticatedClass: ref(""),
    };
  },
  watch: {
    authenticated(newVal) {
      this.authenticatedClass = newVal ? "authenticated" : "";
    },
  },
  created() {
    // Create a new Supabase client
    this.supabase = createClient(
      "https://nankyhhebwlbyytlkozy.supabase.co",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5hbmt5aGhlYndsYnl5dGxrb3p5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njc1MDQ0ODQsImV4cCI6MTk4MzA4MDQ4NH0.oVi7tGrlG5rLPe-xfkwdEzpszFFx5IZ-Wd-fPYoKZIY"
    );
    try {
      // Check if the user is authenticated
      // and update the `authenticated` and `nickname` data properties accordingly
      const authToken = localStorage.getItem(
        "sb-nankyhhebwlbyytlkozy-auth-token"
      );
      let authPayload = {};
      if (authToken) {
        authPayload = JSON.parse(authToken);
      }
      this.nickname = ref(authPayload.user.user_metadata.nickname);
      this.avatarUrl = ref(authPayload.user.user_metadata.avatar_url);
      if (authToken) {
        this.authenticated = ref(true);
      }
    } catch (error) {
      console.error(error);
    }
    setInterval(() => {
      this.ff_pulse();
    }, 30000);
  },
  methods: {
    async ff_pulse() {
      let { data, error } = await this.supabase.rpc("ff_pulse");

      if (error) console.error(error);
      else console.log(data);
    },
    async signOut() {
      let { error } = await this.supabase.auth.signOut();
      if (error) {
        console.error(error);
      } else {
        this.authenticated = false;
      }
    },
  },
  computed: {
    authStatus() {
      return this.authenticated ? "Authenticated" : "Not authenticated";
    },
  },
};
</script>

<style>
#dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  width: 2%;
  height: auto;
}
</style>
