<template>
  <div id="overlayView">
    <div class="frenzy-fund">
      <h1 class="frenzy-fund-title">FrenzyFund</h1>
      <overlayView />
    </div>
  </div>
</template>

<script>
import overlayView from "@/components/OverlayView.vue";

export default {
  name: "streamView",
  components: {
    overlayView,
  },
};
</script>

<style>
#dashboard {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
